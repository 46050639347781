/* @refresh reload */
import { customElement, noShadowDOM } from 'solid-element'
import { Component, createEffect, createSignal, For, Show } from 'solid-js'
import { MAGAZINE_QUERY } from '@/shared/queries/magazin'
import { MagazinQuery, MagazinQueryVariables } from '@/shared/generated/graphql'
import Article from '../magazin-list/Article'
import ArticleSkeleton from '../magazin-list/ArticleSkeleton'
import { useGraphQL } from '@/shared/context/GraphQLContext'

const MagazinTeasers: Component = () => {
	noShadowDOM()

	const newQuery = useGraphQL()
	const itemsPerPage: number = 2
	const [currentPage, setCurrentPage] = createSignal<number>(0)
	const [selectedFilter, setSelectedFilter] = createSignal<string[]>([])

	/* eslint-disable-next-line */
	const [data] = newQuery<MagazinQuery, MagazinQueryVariables>(MAGAZINE_QUERY, () => ({
		id: 27,
		first: itemsPerPage,
		offset: currentPage() * itemsPerPage,
		selected: selectedFilter(),
	}))

	return (
		<div class="magazin-teaser-list">
			<Show when={!data.loading && data()} fallback={<ArticleSkeleton itemsPerPage={itemsPerPage} />}>
				<For each={data()!.pages!.items}>{(item, i) => <Article item={item} i={i()} />}</For>
			</Show>
		</div>
	)
}

customElement('magazin-teasers', MagazinTeasers)
